import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { Observable } from 'rxjs';

import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';

import { SetInvoiceTo, SetDocument } from '../core/store/order';
import { Owner, User, UserForm, UserType } from '../core/models/user';
import { Document } from '../core/models/document';
import { Address } from '../core/models/address';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SetStep3Valid } from '../core/store/ui';

import PlaceResult = google.maps.places.PlaceResult;
import { forceInvoiceToPro } from '../core/data/theme-config';
import { GtmService } from '../core/services/google-tag-manager.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit, AfterViewInit {
  invoiceTo: string;
  $invoiceTo: Observable<string>;
  $isLoggedIn: Observable<boolean>;
  $isStep3Valid: Observable<boolean>;

  otherDocFormGroup: FormGroup;

  UserTypeEnum = UserType;

  user: User;
  userForm: UserForm;
  address: Address;
  owner: Owner;
  userType: number;
  document: Document;

  forceToPro = forceInvoiceToPro;

  hasCompletedStep3: boolean = false;

  constructor(
    private store: Store<AppState>,
    private _formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private gtmService: GtmService
  ) {
    this.store.select(orderSelector.getDocument).subscribe(d => (this.document = d));
    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);

    this.otherDocFormGroup = this._formBuilder.group({
      firstName: [{ value: this.document.firstName, disabled: false }, Validators.required],
      lastName: [{ value: this.document.lastName, disabled: false }, Validators.required],
      street: [{ value: this.document.address.street, disabled: false }, Validators.required],
      number: [{ value: this.document.address.number, disabled: false }, Validators.required],
      zip: [{ value: this.document.address.zip, disabled: false }, Validators.required],
      city: [{ value: this.document.address.city, disabled: false }, Validators.required],
      boxNumber: [{ value: this.document.address.boxNumber, disabled: false }],
      apartmentNumber: [{ value: this.document.address.apartmentNumber, disabled: false }],
      tva: [{ value: this.document.tva, disabled: false }],
      companyName: [{ value: this.document.companyName, disabled: false }]
    });
  }

  ngOnInit() {
    this.$invoiceTo = this.store.select(orderSelector.getInvoiceTo);
    this.$isStep3Valid = this.store.select(uiSelector.getStep3IsValid);

    this.$invoiceTo.subscribe(i => {
      this.invoiceTo = i;
      if (this.invoiceTo !== 'toOther' && this.invoiceTo !== '') {
        this.store.dispatch(new SetStep3Valid(true));
      }
      const formGP = this.otherDocFormGroup;
      formGP.statusChanges.pipe(debounceTime(500)).subscribe(statu => {
        if (this.invoiceTo === 'toOther') {
          const statusValid = statu === 'VALID';
          let oldStatus;
          this.store.select(uiSelector.getStep3IsValid).subscribe(st => (oldStatus = st));
          if (oldStatus !== statusValid) {
            this.store.dispatch(new SetStep3Valid(statusValid));
          }
        }
      });
    });

    this.store.select(orderSelector.getUserInfo).subscribe(u => (this.user = u));
    this.store.select(orderSelector.getUserForm).subscribe(u => (this.userForm = u));
    this.store.select(orderSelector.getOwner).subscribe(o => (this.owner = o));
    this.store.select(orderSelector.getAddress).subscribe(a => (this.address = a));
    this.store.select(orderSelector.getUserType).subscribe(t => (this.userType = t));

    this.otherDocFormGroup.valueChanges.pipe(debounceTime(500)).subscribe(values => {
      const adr: any = {
        street: values.street,
        number: values.number,
        boxNumber: values.boxNumber,
        apartmentNumber: values.apartmentNumber,
        zip: values.zip,
        city: values.city
      };
      const doc: Document = {
        lastName: values.lastName,
        firstName: values.firstName,
        tva: values.tva,
        companyName: values.companyName,
        address: adr
      };

      this.store.dispatch(new SetDocument(doc));
    });
  }

  ngAfterViewInit(): void {
    this.otherDocFormGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  SelectInvoice(type: string) {
    if (type !== this.invoiceTo) {
      this.store.dispatch(new SetInvoiceTo(type));
      if (type === 'toOther') {
        // this.store.dispatch(new SetDocument(new Document()));
        this.otherDocFormGroup.updateValueAndValidity();
      }
    }
  }

  onAutocompleteSelected(pResult: PlaceResult) {
    const address = new Address();
    const addrObj: any = this.getFormattedAddress(pResult);

    address.number = addrObj.num;
    address.street = addrObj.street;
    address.zip = addrObj.zip;
    address.city = addrObj.city;
    address.country = addrObj.country;
    address.lat = addrObj.lat;
    address.lng = addrObj.lng;

    this.otherDocFormGroup.patchValue({
      street: address.street,
      number: address.number,
      zip: address.zip,
      city: address.city
    });

    const doc = { ...this.document };
    doc.address = address;
    this.store.dispatch(new SetDocument(doc));
    this.otherDocFormGroup.markAsDirty();
    this.otherDocFormGroup.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  getFormattedAddress(place) {
    const location_obj = {};
    for (const i in place.address_components) {
      if (place.address_components.hasOwnProperty(i)) {
        const item = place.address_components[i];

        location_obj['formatted_address'] = place.formatted_address;
        if (item['types'].indexOf('locality') > -1) {
          location_obj['city'] = item['long_name'];
        } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
          location_obj['admin_area_l1'] = item['short_name'];
        } else if (item['types'].indexOf('street_number') > -1) {
          location_obj['num'] = item['short_name'];
        } else if (item['types'].indexOf('route') > -1) {
          location_obj['street'] = item['long_name'];
        } else if (item['types'].indexOf('country') > -1) {
          location_obj['country'] = item['long_name'];
        } else if (item['types'].indexOf('postal_code') > -1) {
          location_obj['zip'] = item['short_name'];
        }
      }
    }
    if (place && place.geometry && place.geometry.location.lat) {
      location_obj['lat'] = place.geometry.location.lat();
    }
    if (place && place.geometry && place.geometry.location.lng) {
      location_obj['lng'] = place.geometry.location.lng();
    }
    return location_obj;
  }

  toStep4() {
    if (!this.hasCompletedStep3) {
      this.hasCompletedStep3 = true;
      this.gtmService.emit('order_funnel_step_3_completed');
    }
  }
}
